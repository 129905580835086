import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./client";
import { Col, Row, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);
  document.documentElement.scrollTop = 0;
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
      title,

      slug,
      mainImage{
        asset->{
        _id,
        url
      }
    },
    "name": author->name,
  }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <Row style={{ marginTop: "0em", margin: "auto", maxWidth: "90em", padding: "1em" }}>
        <Col
          sm={24}
          md={16}>
          {allPostsData &&
            allPostsData.map((post, index) => (

              <Col
                sm={24}
                md={24}
                style={{
                  display: "inline",
                  width: "100%",
                  margin: "auto",
                  marginTop: "2em",


                }}
                key={index}
              >
                <div
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "20em",
                      zIndex: -9999,
                      borderRadius: "0em",
                    }}
                    src={post.mainImage.asset.url}
                    alt=''
                  />{" "}
                </div>
                <div style={{ border: "1px solid lightgrey", borderTop: "0px solid lightgrey", borderRadius: " 0em 0em 0.3em 0.3em" }}>
                  <h2
                    className='main-blogs-header'
                    style={{
                      textTransform: "none",
                      fontSize: "20px",
                      fontWeight: 500,
                      margin: "auto",
                      maxWidth: "100%",
                      top: "0em",
                      color: "black",
                      lineHeight: "1em",
                      paddingLeft: 10,
                    }}
                  >
                    {post.name}
                  </h2>
                  <h2
                    className='main-blogs-header'
                    style={{
                      textTransform: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      margin: "auto",
                      maxWidth: "100%",
                      top: "0em",
                      color: "black",
                      marginTop: "0em",
                      paddingLeft: 10,
                      paddingTop: "0em",
                      lineHeight: "1.3em",
                    }}
                  >
                    {post.title}
                  </h2>
                  <div style={{ backgroundColor: "white", borderTop: "1px solid lightgrey", height: "3.8em", maxWidth: "100vw", }}>
                    <Link to={"/" + post.slug.current} key={post.slug.current} style={{}}>
                      <h2
                        className='main-blogs-header'
                        style={{
                          textTransform: "none",
                          backgroundColor: "#CD2934",
                          padding: "1em",
                          borderRadius: "0.4em",
                          fontSize: "11px",
                          fontWeight: 500,
                          margin: "auto",
                          maxWidth: "100%",
                          float: "right",
                          color: "white",
                          //background:
                          //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                          marginTop: "0.9em",
                          marginRight: "1em",
                          lineHeight: "1em",
                        }}
                      >
                        Read more <SwapRightOutlined />
                      </h2>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </Col>
        <Col
          sm={0}
          md={1}></Col>
        <Col
          sm={24}
          md={6} style={{
            border: "1px solid lightgrey", borderRadius: " 0em 0em 0.3em 0.3em", marginTop: "2em", paddingBottom: "2.5em",
            height: "100%", width: "100%"
          }}>
          <h2
            className='main-blogs-header'
            style={{
              textTransform: "none",
              fontSize: "20px",
              fontWeight: 500,
              margin: "auto",
              maxWidth: "100%",
              top: "0em",
              color: "black",
              lineHeight: "1em",
              paddingLeft: 10,
            }}
          >
            Recent Posts
          </h2>
          {allPostsData &&
            allPostsData.map((post, index) => (

              <Col
                sm={24}
                md={24}
                style={{
                  width: "100%",
                  margin: "auto",
                  marginTop: "1em",

                }}
                key={index}
              >
                <Link to={"/" + post.slug.current} key={post.slug.current} style={{}}>
                  <div>
                    <h2
                      className='main-blogs-header'
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        margin: "auto",
                        maxWidth: "100%",
                        color: "black",
                        lineHeight: "1em",
                        paddingLeft: 10,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      {post.title}
                    </h2>
                  </div>
                </Link>
              </Col>
            ))}
        </Col>
      </Row>
    </div >
  );
}

class Blogs extends Component {
  render() {
    return (
      <React.Fragment>
        {" "}
        <div
          className='banner-item banner-2x banner-bg-download-center color-inher'
          style={{ padding: "30px 15px 1em 1em", margin: "auto", marginTop: "9em", textAlign: "left", maxWidth: "90em" }}
        >
          <h3 className='f-weight-300'>
            <strong>Mahindra News</strong>
            <br />
            Blog center
          </h3>
        </div>

        <AllPosts />

      </React.Fragment>
    );
  }
}

export default Blogs;
