import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, List, Typography } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "top",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  componentDidMount() {
    fetch(
      "https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition } = this.state;
    const { pexUnits } = this.props;
    return (
      <div>
        <section className='block-sl'> <div className='banner-item banner-2x banner-bg-download-center color-inher'>
          {" "}
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='logo'
            style={{ width: "12em", marginBottom: "1em" }}
          />
          <h3 className='f-weight-300'>
            <strong>JOB LISTINGS</strong>
            <br />
            NEW VACANCIES NOW AVAILABLE.
          </h3>
        </div>
          <div className='container-custom'>
            <div className='row'>
              <div
                className='col-sm-12 col-md-12 col-lg-12'
                style={{ zIndex: 10, marginBottom: "2em" }}
              >

                <List

                  bordered


                > {this.state.pexUnits.map((pexUnits) => {
                  return pexUnits.jobTitle ? (
                    <List.Item>
                      <List.Item.Meta
                        // avatar={<Avatar src={item.avatar} />}
                        title={<span>{pexUnits.jobTitle}</span>}
                        description={<span>Location: {pexUnits.jobLocation}<br />{pexUnits.jobEmail ? <span>Contact: <a href={"mailto:" + pexUnits.jobEmail}> {pexUnits.jobEmail}</a> </span> : null}</span>}
                      />
                      {pexUnits.jobDescription}
                      <div style={{
                        float: window.isPhone ? "" : "right", color: "lightgray", marginTop: window.isPhone ? "0.5em" : ""
                      }}>
                        Published:  {pexUnits.datePosted}</div>
                    </List.Item>

                  ) : null;
                })}
                </List>

              </div></div></div>
        </section > {" "}

      </div >
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      <div id='wrap' className='color1-inher'>

        {this.renderSearchBar()}
        <div id='wrap-body' className='p-t-lg-45'>
          <div className='container-custom'>{this.props.children}</div>
        </div>

      </div>
    );
  }
}
export default withRouter(SharedLayout);
