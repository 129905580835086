import React from "react";
import { Row, Col, Button, Form, Input, Modal, Divider } from "antd";
import AllPosts from "./Blogs/all-posts";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    console.log(this.props.selectedStock);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: [
        // "leads@megatheron.co.za",
        "leads@megatheron.co.za",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New Newsletter Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Newsletter Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Newsletter Enquiry from <br /> Mahindra Mahikeng website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                         
                        
                             
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Mahindra Mahikeng</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,

        email: "",
      });
    });
  };
  render() {
    return (
      <div>
        <footer id='wrap-footer' className='desktop-view'>
          <div
            style={{ margin: "auto", display: "inline-flex", width: "100vw", backgroundColor: "white" }}
          >
            <Row
              style={{
                width: "100vw",
                margin: "auto",
                marginTop: "4em",
                marginBottom: "4em",
              }}
            >
              <Col span={24}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    lineHeight: "0.2em",
                    marginBottom: "1em",

                  }}
                >
                  Making Headlines
                </h1>

              </Col>

              <Col span={24}>
                <AllPosts />
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>

                <Link to='./blogs'>
                  <Button
                    style={{
                      fontFamily: "Poppins Medium",
                      border: "1px solid #CF0A2C",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0.4em",
                      backgroundColor: "#CF0A2C",
                      color: "white",
                      height: "55px",
                      width: "15em",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "3em",
                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    View All Blogs  <i
                      class='fa fa-long-arrow-right'
                      aria-hidden='true'
                      style={{ marginLeft: "0.5em" }}
                    ></i>
                  </Button>{" "}

                </Link>
              </Col>
            </Row>
          </div>{" "}

          <div className='footer-top' style={{ backgroundColor: "black" }}>

            <div className='container-custom'>
              <div className='p-l-r'>
                <div className='row'>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mahindra-footer.png`}
                    alt='logo'
                    style={{
                      width: "58em",
                      bottom: 0,
                      opacity: 0.2,
                      position: "absolute"
                    }}
                  />

                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-30'>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/mahindra-white.png`}
                        alt='logo'
                        style={{
                          width: "20em",


                        }}
                      />
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-30'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>
                        {/* <li style={{ color: "white" }}>
                          <Link to={"/stock"}>Used Vehicles</Link>
                        </li>
                        <Divider type="vertical" /> */}
                        <li style={{ color: "white" }}>
                          <Link to={"/blogs"}>Blogs</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to={"/contactUs"}>Contact Us</Link>
                        </li>

                        {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                      </ul>
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-0'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://www.facebook.com/MahindraMahikeng'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-facebook'
                            />
                          </a>
                        </li>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://twitter.com/Mahindra_Auto'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-twitter'
                            />
                          </a>
                        </li>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://www.youtube.com/user/mahindraautomotive'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-youtube'
                            />
                          </a>
                        </li>
                      </ul>
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-0'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>

                        <li style={{ color: "white" }}>
                          <Link to='/legal-notice'>Legal Notice</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/POPIA-manual'>PAIA & POPIA</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/privacy-policy'>Privacy Statement</Link>
                        </li>{" "}
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/terms'>Terms of Use</Link>
                        </li>

                        {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                      </ul>

                      <li style={{ color: "white", marginTop: "1em" }}>
                        <span style={{ marginLeft: "-6em" }} >In Affiliation with</span>
                        <br />
                        <a href="https://www.megatheron.co.za/" target="_blank">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/logo-total-white.png`}
                            alt='logo'
                            style={{
                              width: "15em",
                              marginBottom: "1.5em",
                              marginTop: "-1em",

                            }}
                          />
                        </a>
                      </li>
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-20'>

                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: 200,
                          fontFamily: "Poppins Medium",
                          lineHeight: "0em",

                          fontFamily: "Poppins Medium",
                          color: "white",
                        }}
                      >
                        © 2024 mahindra. All rights reserved
                      </p>{" "}
                    </Col>{" "}
                  </Row>
                </div >
              </div >
            </div > {" "}
          </div >
        </footer > {" "}
        < footer id='wrap-footer' className='mobile-view' >
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h6
              style={{
                textAlign: "center",
                margin: "auto",
                marginTop: "1em",
              }}
            >
              Stay up to date on the latest blogs
            </h6>
            <h1
              style={{
                textAlign: "center",
                fontSize: "35px",
                lineHeight: "0.2em",
              }}
            >
              Blogs & Reviews
            </h1>
            <p
              style={{
                textAlign: "center",
                color: "#4F5665",
                fontSize: "16px",
                lineHeight: "1.2em",
                padding: "1em",
                paddingBottom: "0em",
              }}
            >
              All things motoring – from reviews and helpful articles to news
              and special offers.
            </p>{" "}
            <br />
            <Link to='./blogs'
              className='ht-btn ht-btn-default m-t-lg-0 p-l-lg-30 p-r-lg-30'
              style={{ textTransform: "none", borderRadius: "0.4em", marginBottom: "2em", }}
            >

              View All Blogs

            </Link>
            <Col span={24}>
              <AllPosts />
            </Col>
          </div>

          <div className='footer-top' style={{ backgroundColor: "black", marginTop: "-1em", paddingTop: "2em" }}>

            <div className='container-custom'>
              <div className='p-l-r'>
                <div className='row'>
                  {/* <img
                    src={`${process.env.PUBLIC_URL}/images/mahindra-footer.png`}
                    alt='logo'
                    style={{
                      width: "58em",
                      bottom: 0,
                      opacity: 0.2,
                      position: "absolute"
                    }}
                  /> */}

                  <Row style={{ textAlign: "center", }}>
                    <Col span={24} className='m-t-lg-30'>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/mahindra-white.png`}
                        alt='logo'
                        style={{
                          width: "20em",


                        }}
                      />
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center", borderBottom: "1px solid lightgrey", margin: "auto" }}>
                    <Col span={24} className='m-t-lg-30'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>
                        {/* <li style={{ color: "white" }}>
                          <Link to={"/new-vehicles"}>Vehicle Range</Link>
                        </li>
                        <Divider type="vertical" /> */}
                        {/* <li style={{ color: "white" }}>
                          <Link to={"/stock"}>Used Vehicles</Link>
                        </li>
                        <Divider type="vertical" /> */}
                        {/* <li style={{ color: "white" }}>
                          <Link to={"/stock"}>Used Vehicles</Link>
                        </li>
                        <Divider type="vertical" /> */}
                        <li style={{ color: "white" }}>
                          <Link to={"/blogs"}>Blogs</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to={"/contactUs"}>Contact Us</Link>
                        </li>
                        {/*<li>
          <a>FAQ</a>
        </li>*/}
                      </ul>
                    </Col>{" "}
                  </Row>

                  <Row style={{ textAlign: "center", marginTop: "1.5em" }}>
                    <Col span={24} className='m-t-lg-0'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>

                        <li style={{ color: "white" }}>
                          <Link to='/legal-notice'>Legal Notice</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/POPIA-manual'>PAIA & POPIA</Link>
                        </li>
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/privacy-policy'>Privacy Statement</Link>
                        </li>{" "}
                        <Divider type="vertical" />
                        <li style={{ color: "white" }}>
                          <Link to='/terms'>Terms of Use</Link>
                        </li>


                        {/*<li>
          <a>FAQ</a>
        </li>*/}
                      </ul>
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-0'>


                      <ul className='list-footer-default' style={{ display: "inline-flex" }}>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://www.facebook.com/MahindraMahikeng'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-facebook'
                            />
                          </a>
                        </li>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://twitter.com/Mahindra_Auto'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-twitter'
                            />
                          </a>
                        </li>
                        <li style={{ marginRight: "2em" }}>
                          <a
                            href='https://www.youtube.com/user/mahindraautomotive'
                            target='_blank'
                          >
                            <i
                              style={{ color: "white", fontSize: "30px" }}
                              className='fa fa-youtube'
                            />
                          </a>
                        </li>
                      </ul>
                      <li style={{ color: "white", marginTop: "1em" }}>
                        <span style={{ marginLeft: "-6em" }} >In Affiliation with</span>
                        <br />
                        <a href="https://www.megatheron.co.za/" target="_blank">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/logo-total-white.png`}
                            alt='logo'
                            style={{
                              width: "15em",
                              marginBottom: "1.5em",
                              marginTop: "-1em",

                            }}
                          />
                        </a>
                      </li>
                    </Col>{" "}
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={24} className='m-t-lg-20'>

                      <p
                        style={{
                          fontSize: "11px",
                          fontWeight: 200,

                          lineHeight: "0em",

                          fontFamily: "Arial",
                          color: "white",
                        }}
                      >
                        © 2024 mahindra. All rights reserved
                      </p>{" "}
                    </Col>{" "}
                  </Row>
                </div >
              </div >
            </div > {" "}
          </div >
        </footer > {" "}
        < Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ width: "65%", margin: "auto" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for subscribing to the
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              The Mega Theron Group Newsletter.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              We'll keep you updated on all our exclusive offerings.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal >
      </div >
    );
  }
}

export default Footer;
