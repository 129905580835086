import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchFiltredStock, turnOnHomefilter } from "../redux/actions";
import { withRouter } from "react-router";
import { Select, Slider } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import $ from "jquery";

if (window.innerWidth <= 770 || window.innerHeight <= 770) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
class SearchBarHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageSize: 12,
      displayType: "cards",
      fetchedStock: props.fetchedStock,
      renderedStock: {},
      years: [],
      makes: [],
      variants: [],
      models: [],
      mileages: [],
      allStock: [],
      buttonSearchClicked: false,
      modelYear: "",
      make: "",
      series: "",
      mileage: "",
      condition: "",
      bodyType: "",
      transmission: "",
      stockDrive: "",
      //features: "",
      branch: "",
      extColour: "",
      fuelType: "",
      onSale: "",
      modelDesc: "",
      checked: true,
      priceRange: "R5,000 - R900,000",
      yearRange: " 2000 -  2023",
      conditionAll: false,
      stock: props.stock,
      stock: [],
    };
    this.selectYear = this.selectYear.bind(this);
    this.selectMake = this.selectMake.bind(this);
    this.selectModel = this.selectModel.bind(this);
    this.selectVariant = this.selectVariant.bind(this);
    //this.setCarMileage = this.setCarMileage.bind(this);
    this.setCarCondition = this.setCarCondition.bind(this);
    this.setConditionAll = this.setConditionAll.bind(this);
    this.setCarConditionNew = this.setCarConditionNew.bind(this);
    this.setCarConditionUsed = this.setCarConditionUsed.bind(this);
    this.setCarConditionDemo = this.setCarConditionDemo.bind(this);

    //this.setCarBody = this.setCarBody.bind(this);
    //this.setCarTransmission = this.setCarTransmission.bind(this);
    //this.setCarDriveTrain = this.setCarDriveTrain.bind(this);
    //this.setCarFeatures = this.setCarFeatures.bind(this);
    //this.setCarBranch = this.setCarBranch.bind(this);
    //this.setCarColour = this.setCarColour.bind(this);
    //this.setCarFuelType = this.setCarFuelType.bind(this);
    //this.setCarOnSale = this.setCarOnSale.bind(this);
  }
  componentWillMount = () => {
    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({ stock: result.allStock });
    });
  };
  buttonSearchClicked() {
    if (this.state !== null) {
      var {
        modelYear,
        make,
        series,
        modelDesc,
        condition,
        priceRange,
        yearRange,
      } = this.state;
      var filter = {};
      priceRange = $("#priceslider").val();
      yearRange = $("#yearslider").val();
      filter = {
        modelYear,
        make,
        series,
        modelDesc,
        condition,
        priceRange,
        yearRange,
      };
      this.props.fetchFiltredStock(filter);
      this.props.turnOnHomefilter();
    }

    this.props.history.push("/stock");
    this.props.filters(filter);
  }
  selectYear = (modelYear) => {
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/evolve/findclientmakes?year=" +
      modelYear,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        makes: result.makes,

        modelYear: modelYear,
        make: undefined,
      });
    });
  };
  selectManual = (checked) => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };
  selectMake = (make) => {
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/evolve/findclientmodels?make=" +
      make,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({ models: result.models, make: make, series: undefined });
    });
  };
  selectModel = (series) => {
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/evolve/findclientvariants?model=" +
      series +
      "&year=" +
      this.state.modelYear,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        variants: result.variants,
        series: series,
        modelDesc: undefined,
      });
    });
  };
  selectVariant = (modelDesc) => {
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/evolve/findmileages?variant=" +
      modelDesc +
      "&year=" +
      this.state.modelYear,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        mileages: result.mileages,
        modelDesc: modelDesc,
        mileage: undefined,
      });
    });
  };
  setCarMileage(value) {
    this.setState({ mileage: value });
  }
  setCarConditionNew(value) {
    this.setState({ condition: 'NEW' });
  }
  setCarConditionUsed(value) {
    this.setState({ condition: "USED" });
    // this.setState({ condition: "DEMO" });
  }
  setCarConditionDemo(value) {
    this.setState({ condition: "DEMO" });
  }
  setCarCondition(value) {
    this.setState({ condition: value });
  }
  //setCarBody(value) {
  //  this.setState({ bodyType: value });
  //}
  //setCarTransmission(value) {
  //  this.setState({ transmission: value });
  //}
  //setCarDriveTrain(value) {
  //  this.setState({ stockDrive: value });
  //}
  //setCarFeatures(value) {
  //  this.setState({ features: value });
  //}
  //setCarBranch(value) {
  //  this.setState({ branch: value });
  //}
  //setCarColour(value) {
  //  this.setState({ extColour: value });
  //  if (this.state !== null) {
  //    var { extColour, priceRange } = this.state;
  //    var filter = {};
  //    priceRange = $("#priceslider").val();

  //    filter = {
  //      extColour,
  //      priceRange,
  //    };
  //    this.props.fetchFiltredStock(filter);

  //    this.setState({ priceRange });
  //  }
  //  this.setState({ buttonSearchClicked: true });
  //  this.props.turnOffHomefilter();
  //}

  setCarFuelType(value) {
    this.setState({ fuelType: value });
  }
  setCarOnSale(event) {
    this.setState({ onSale: event.target.id });
  }
  setActivePage(activePage) {
    this.setState({ activePage: activePage });
  }
  setPageSize(size) {
    this.setState({ activePage: 1, pageSize: size });
  }
  setDisplayType(displayType) {
    this.setState({ displayType });
  }
  setConditionAll() {
    this.state.conditionAll !== true
      ? this.setState({ conditionAll: true })
      : this.setState({ conditionAll: false });
  }
  render() {
    const duplicateCheck = [];
    return (
      <div>
        {window.isPhone ? (
          <div className='search-1 m-t-sm-20 m-b-lg-10'>
            <div className='container-custom' style={{ marginTop: "1.5em" }}>
              <div className='search-option p-lg-30 p-b-lg-15 p-b-sm-30 p-r-sm-35 p-xs-15'>
                <div className='col-sm-12 col-md-12 col-lg-12 p-r-lg-0 p-l-lg-0 p-r-xs-15'>
                  <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-25 p-r-lg-0  p-r-xs-15 text-center'>
                    <span
                      style={{ fontSize: "25px", fontWeight: 500 }}
                      className='p-t-lg-0'
                    >
                      I’M LOOKING FOR
                    </span>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 p-r-lg-0 p-r-xs-15 m-t-lg-5 text-center'>
                    <label
                      htmlFor='NEW'
                      className='checkbox-container'
                      style={{ display: "inline-block" }}
                    >
                      <input
                        //style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionNew}
                        type='checkbox'
                        checked={this.state.conditionAll === true ? true : null}
                        id='NEW'
                      //name="stock1"

                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>New Cars</span>
                    </label>

                    <label
                      htmlFor='Used'
                      className='checkbox-container'
                      style={{ display: "inline-block", marginLeft: "1em" }}
                    >
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionUsed}
                        type='checkbox'
                        id='Used'
                        checked={this.state.conditionAll === true ? true : null}
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>Used Cars</span>
                    </label>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 p-r-lg-0 p-r-xs-15 m-t-lg-10 text-center'>
                    <label
                      htmlFor='Demo'
                      className='checkbox-container'
                      style={{ display: "inline-block" }}
                    >
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionDemo}
                        type='checkbox'
                        id='Demo'
                        checked={this.state.conditionAll === true ? true : null}
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>Demo Cars</span>
                    </label>
                    <label
                      htmlFor='All'
                      className='checkbox-container'
                      style={{ display: "inline-block", marginLeft: "1em" }}
                    >
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onChange={this.setConditionAll}
                        type='checkbox'
                        id='All'
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>All Cars</span>
                    </label>
                  </div>
                  {/*<div className="col-sm-2 col-md-2 col-lg-2 m-b-lg-15 p-r-lg-0 p-r-xs-15">
                 <label>AVAILABLE 62 STOCK</label>
               </div>*/}
                </div>
                <div className='row  m-t-lg-15'>
                  <div className='col-sm-12 col-md-7 col-lg-7'>
                    <div className='row'>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <input
                          id='yearslider'
                          type='text'
                          disabled
                          className='slider_amount_year m-t-lg-0 m-t-xs-0 m-t-sm-10'
                        />
                        <div
                          id='spanrangeyear'
                          //onChange={this.selectYear}
                          className='slider-range-year'
                        ></div>
                        {/*<Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Year'
                          onChange={this.selectYear}
                        >
             

                          {this.state.stock
                            .sort((a, b) => b.modelYear - a.modelYear)
                            .map((stock) => {
                              if (duplicateCheck.includes(stock.modelYear))
                                return null;
                              duplicateCheck.push(stock.modelYear);

                              return (
                                <Option
                                  key={stock.modelYear}
                                  value={stock.modelYear}
                                >
                                  {stock.modelYear}
                                </Option>
                              );
                            })}
                        </Select>*/}
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Make'
                          onChange={this.selectMake}
                        >
                          {/*<Option value=''>All Makes</Option>*/}
                          {this.state.modelYear !== ""
                            ? this.state.makes.map((make) => {
                              return (
                                <Option key={make} value={make}>
                                  {make}
                                </Option>
                              );
                            })
                            : this.state.stock
                              .sort((a, b) => a.make.localeCompare(b.make))
                              .map((stock) => {
                                if (duplicateCheck.includes(stock.make))
                                  return null;
                                duplicateCheck.push(stock.make);

                                return (
                                  <Option key={stock.make} value={stock.make}>
                                    {stock.make}
                                  </Option>
                                );
                              })}
                        </Select>
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          disabled={
                            this.state.make === "" ||
                              this.state.make === undefined
                              ? true
                              : false
                          }
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Model'
                          onChange={this.selectModel}
                        >
                          {/*<Option key={0} value=''>
                            All Models
                          </Option>*/}
                          {this.state.models.map((series) => {
                            return (
                              <Option key={series} value={series}>
                                {series}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          disabled={
                            this.state.series === "" ||
                            this.state.series === undefined
                          }
                          placeholder='Derivative'
                          onChange={this.selectVariant}
                        >
                          {/*<Option key={0} value=''>
                            All Derivatives
                          </Option>*/}
                          {this.state.variants.map((modelDesc) => {
                            return (
                              <Option key={modelDesc} value={modelDesc}>
                                {modelDesc}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-5 col-md-3 col-lg-3'>
                    <input
                      id='priceslider'
                      type='text'
                      disabled
                      className='slider_amount m-t-lg-0 m-t-xs-0 m-t-sm-10'
                    />
                    <div id='spanrange' className='slider-range'></div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12 p-r-sm-0'>
                    <button
                      type='button'
                      className='ht-btn ht-btn-default m-t-lg-25 '
                      onClick={this.buttonSearchClicked.bind(this)}
                    >
                      <i className='fa fa-search'></i> Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='search-1 m-t-sm-30'>
            <div className='container-custom'>
              <div className='search-option p-lg-30 p-b-lg-15 p-b-sm-30 p-r-sm-35 p-xs-15'>
                <div className='col-sm-12 col-md-12 col-lg-12 p-r-lg-0 p-l-lg-0 p-r-xs-15'>
                  <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-25 p-r-lg-0  p-r-xs-15'>
                    <span
                      style={{ fontSize: "25px", fontWeight: 500 }}
                      className='p-t-lg-0'
                    >
                      I’M LOOKING FOR
                    </span>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 p-r-lg-0 p-r-xs-15 m-t-lg-5'>
                    <label htmlFor='NEW' className='checkbox-container'>
                      <input
                        //style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionNew}
                        type='checkbox'
                        checked={this.state.conditionAll === true ? true : null}
                        id='NEW'
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>New Cars</span>
                    </label>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 p-r-lg-0 p-r-xs-15 m-t-lg-5'>
                    <label htmlFor='Used' className='checkbox-container'>
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionUsed}
                        type='checkbox'
                        id='Used'
                        checked={this.state.conditionAll === true ? true : null}
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>Used Cars</span>
                    </label>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 p-r-lg-0 p-r-xs-15 m-t-lg-5'>
                    <label htmlFor='Demo' className='checkbox-container'>
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onClick={this.setCarConditionDemo}
                        type='checkbox'
                        id='Demo'
                        checked={this.state.conditionAll === true ? true : null}
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>Demo Cars</span>
                    </label>
                  </div>
                  <div className='col-sm-1 col-md-1 col-lg-1 p-r-lg-0 p-r-xs-15 m-t-lg-5'>
                    <label htmlFor='All' className='checkbox-container'>
                      <input
                        style={{ transform: "scale(1.5)" }}
                        onChange={this.setConditionAll}
                        type='checkbox'
                        id='All'
                      //name="stock1"
                      //value="Bike"
                      />
                      <span className='checkmark'></span>
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "16px" }}>All</span>
                    </label>
                  </div>
                  <div
                    className='col-sm-2 col-md-2 col-lg-2 m-b-lg-15 p-r-lg-0 p-r-xs-15'
                    style={{ paddingTop: "0.5em", textAlign: "center" }}
                  >
                    <Link
                      to='/stock'
                      style={{
                        color: "red",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      More Filters{"   "}
                      <i
                        class='fa fa-long-arrow-right'
                        aria-hidden='true'
                        style={{ marginLeft: "0.2em" }}
                      ></i>
                    </Link>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-7 col-lg-7'>
                    <div className='row'>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <input
                          id='yearslider'
                          type='text'
                          disabled
                          className='slider_amount_year m-t-lg-0 m-t-xs-0 m-t-sm-10'
                        />
                        <div
                          id='spanrangeyear'
                          //onClick={this.selectYear}
                          className='slider-range-year'
                        ></div>
                        {/*<Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Year'
                          onChange={this.selectYear}
                          //disabled={this.state.make !== "" ? true : false}
                        >
                        

                          {this.state.stock
                            .sort((a, b) => b.modelYear - a.modelYear)
                            .map((stock) => {
                              if (duplicateCheck.includes(stock.modelYear))
                                return null;
                              duplicateCheck.push(stock.modelYear);

                              return (
                                <Option
                                  key={stock.modelYear}
                                  value={stock.modelYear}
                                >
                                  {stock.modelYear}
                                </Option>
                              );
                            })}
                        </Select>*/}
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Make'
                          onChange={this.selectMake}
                        //disabled={this.state.modelYear === "" ? true : false}
                        >
                          {this.state.modelYear !== ""
                            ? this.state.makes.map((make) => {
                              return (
                                <Option key={make} value={make}>
                                  {make}
                                </Option>
                              );
                            })
                            : this.state.stock
                              .sort((a, b) => a.make.localeCompare(b.make))
                              .map((stock) => {
                                if (duplicateCheck.includes(stock.make))
                                  return null;
                                duplicateCheck.push(stock.make);
                                return (
                                  stock.megaDealers === "MAHINDRA" ?
                                    <Option key={stock.make} value={stock.make}>
                                      {stock.make}
                                    </Option> : null
                                );
                              })}
                          {/* {this.state.modelYear !== ""
                            ? this.state.makes.map((make) => {
                              return (
                                <Option key={make} value={make}>
                                  {make}
                                </Option>
                              );
                            })
                            : this.state.stock
                              .sort((a, b) => a.make.localeCompare(b.make.replace(/\s/g, '')))
                              .map((stock) => {
                                if (duplicateCheck.includes(stock.make.replace(/\s/g, '')))
                                  return null;
                                duplicateCheck.push(stock.make.replace(/\s/g, ''));
                                return (
                                  <Option key={stock.make} value={stock.make}>
                                    {stock.make}
                                  </Option>
                                );
                              })}*/}
                        </Select>
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          disabled={
                            this.state.make === "" ||
                              this.state.make === undefined
                              ? true
                              : false
                          }
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          placeholder='Model'
                          onChange={this.selectModel}
                        >
                          {/*<Option key={0} value=''>
                            All Models
                          </Option>*/}
                          {this.state.models.map((series) => {
                            return (
                              <Option key={series} value={series}>
                                {series}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 m-b-lg-15 p-r-lg-0 p-r-xs-15'>
                        <Select
                          //mode='multiple'
                          allowClear
                          bordered={false}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "0em",
                            paddingLeft: "0em",
                            width: "95%",
                          }}
                          disabled={
                            this.state.series === "" ||
                            this.state.series === undefined
                          }
                          placeholder='Derivative'
                          onChange={this.selectVariant}
                        >
                          {/*<Option key={0} value=''>
                            All Derivatives
                          </Option>*/}
                          {this.state.variants.map((modelDesc) => {
                            return (
                              <Option key={modelDesc} value={modelDesc}>
                                {modelDesc}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-5 col-md-3 col-lg-3'>
                    <input
                      id='priceslider'
                      type='text'
                      disabled
                      className='slider_amount m-t-lg-0 m-t-xs-0 m-t-sm-10'
                    />
                    <div id='spanrange' className='slider-range'></div>
                  </div>
                  <div className='col-sm-3 col-md-2 col-lg-2 p-r-sm-0 pull-right pull-left-xs'>
                    <button
                      type='button'
                      className='ht-btn ht-btn-default m-t-lg-0 m-t-sm-10 pull-right pull-left-xs'
                      onClick={this.buttonSearchClicked.bind(this)}
                    >
                      <i className='fa fa-search'></i> Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { fetchFiltredStock, turnOnHomefilter })(
  withRouter(SearchBarHome)
);
