import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    window.reRenderRangeSlider();
    var stock = this.props.stock;
    fetch(
      "https://www.devvcsappcloud.com/mtgroup/api/stock/findallstockclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
    fetch(
      "https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "black",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];
    return (
      <div>
        <div
          className="wrap-body-inner desktop-view"
          id="NewVehicles"
          style={{ background: "black", marginTop: "-0.5em" }}
        >
          <div
            id="our-range"
            className="product product-grid product-grid-2 stock p-t-sm-35 "
            style={{ maxWidth: "100em", margin: "auto", paddingTop: "2em" }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/mahindra-back.png`}
              alt=""
              style={{
                position: "absolute",
                height: "50%",
                right: 0,
                opacity: 0.2,
                marginTop: "-1.5em",
              }}
            />
            <div style={{ textAlign: "center", marginBottom: "3em" }}>
              <h2 style={{ color: "white" }}>RANGE OF VEHICLES</h2>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock.map((stock, index, props) => {
                  return stock.webVisible &&
                    stock.homeVisible &&
                    stock.megaDealers === "MAHINDRA" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        <Link
                          className="product-img"
                          onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/stocko`}
                        >
                          {stock.frontLeftImage ? (
                            <img
                              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div style={{ textAlign: "left", padding: "0.5em" }}>
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em" }}
                          >
                            <Link
                              onClick={() => this.props.fetchSingleStock(stock)}
                              to={`/stocko`}
                            >
                              <span
                                className="f-20  m-t-lg-0 mn-cl"
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  color: "black",
                                }}
                              >
                                {stock.series}
                                <br />
                              </span>
                              <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", textTransform: "uppercase", color: "#E00F2F" }}>

                                <CurrencyFormat
                                  value={stock.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"From R"}
                                  //format='R### ### ### ###'
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name"></div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </Slider>
            </div>
          </div>
          <div
            id="about-mahindra"
            style={{
              background: "white",
              padding: 0,

              marginTop: "6em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  paddingTop: "8em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "40px",
                      lineHeight: "0.2em",
                    }}
                  >
                    <span style={{ color: "rgb(207, 10, 44)" }}> ABOUT</span>{" "}
                    MAHINDRA MAHIKENG
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      paddingTop: "1em",
                      color: "black",
                      fontSize: "16px",
                      lineHeight: "1.5em",
                    }}
                  >
                    What began as a steel-trading venture in 1945, steadily
                    turned into a global brand, spanning nations and industries.
                    This is a story with an upward curve, narrating how an
                    Indian company paved its way to become a global powerhouse.
                    <br />
                    <br />
                    Mahindra SA was established in 1996, bringing both our
                    global name and expertise to South Africa. Since then, we
                    have become the country’s fastest growing passenger vehicle
                    and bakkie brand. Our success also led to the opening of our
                    first local assembly plant in 2018.
                    <br />
                    <br />
                    We strive to continue to make our Mahindra mark, offering
                    the people of South Africa world-class SUV, bakkie and
                    commercial vehicles.
                  </p>
                  <Link
                    to={"/ContactUs"}
                    className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none", borderRadius: "0.4em" }}
                  >
                    Get in Touch{" "}
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
                <Col span={12}></Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt="logo"
                  style={{
                    width: "50em",
                    position: "absolute",
                    right: 0,
                    marginTop: "-5em",
                  }}
                />
              </Row>
            </div>
          </div>
        </div>

        <div
          className="wrap-body-inner mobile-view"
          id="NewVehicles"
          style={{ background: "black", marginTop: "-0.5em" }}
        >
          <div
            id="our-range-mobile"
            className="product product-grid product-grid-2 stock p-t-sm-35 "
            style={{
              maxWidth: "100em",
              margin: "auto",
              marginTop: "-1.5em",
              paddingTop: "2em",
              paddingBottom: "2em",
              backgroundColor: "black",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/mahindra-back.png`}
              alt=""
              style={{
                position: "absolute",
                right: 0,
                opacity: 0.2,
                marginTop: "-1.5em",
              }}
            />
            <div style={{ textAlign: "center", marginBottom: "3em" }}>
              <h2 style={{ color: "white" }}>RANGE OF VEHICLES</h2>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock.map((stock, index, props) => {
                  return stock.webVisible &&
                    stock.homeVisible &&
                    stock.megaDealers === "MAHINDRA" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        <Link
                          className="product-img"
                          onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/stocko`}
                        >
                          {stock.frontLeftImage ? (
                            <img
                              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div style={{ textAlign: "center", padding: "0.5em" }}>
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em" }}
                          >
                            <Link
                              onClick={() => this.props.fetchSingleStock(stock)}
                              to={`/stocko`}
                            >
                              <span
                                className="f-20  m-t-lg-0 mn-cl"
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  color: "black",
                                }}
                              >
                                {stock.series}
                                <br />
                              </span>
                              <span
                                className="f-20  m-t-lg-0 mn-cl"
                                style={{
                                  display: "block",
                                  textTransform: "none",
                                  textTransform: "uppercase",
                                  color: "#E00F2F",
                                }}
                              >
                                <CurrencyFormat
                                  value={stock.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"From R"}
                                  //format='R### ### ### ###'
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name"></div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </Slider>
            </div>
          </div>
          <div
            id="about-mahindra"
            style={{
              background: "white",
              padding: 0,
              marginTop: "6em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  textAlign: "center",
                  maxWidth: "90em",
                  margin: "auto",
                  // paddingTop: "8em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={24}>
                  <h1
                    style={{
                      fontSize: "30px",
                      lineHeight: "0.2em",
                    }}
                  >
                    <span style={{ color: "rgb(207, 10, 44)" }}> ABOUT</span>{" "}
                    MAHINDRA MAHIKENG
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "1.5em",
                      paddingTop: "1em",
                      color: "black",
                      fontSize: "16px",
                      lineHeight: "1.5em",
                    }}
                  >
                    What began as a steel-trading venture in 1945, steadily
                    turned into a global brand, spanning nations and industries.
                    This is a story with an upward curve, narrating how an
                    Indian company paved its way to become a global powerhouse.
                    <br />
                    <br />
                    Mahindra SA was established in 1996, bringing both our
                    global name and expertise to South Africa. Since then, we
                    have become the country’s fastest growing passenger vehicle
                    and bakkie brand. Our success also led to the opening of our
                    first local assembly plant in 2018.
                    <br />
                    <br />
                    We strive to continue to make our Mahindra mark, offering
                    the people of South Africa world-class SUV, bakkie and
                    commercial vehicles.
                  </p>
                  <Link
                    to={"/ContactUs"}
                    className="ht-btn ht-btn-default m-t-lg-0 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none", borderRadius: "0.4em" }}
                  >
                    Get in Touch{" "}
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
                {/* <Col span={12}>
                </Col> */}
                {/* <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
                /> */}
              </Row>
            </div>
          </div>
        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
