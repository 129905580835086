import React, { Component } from "react";
import { Row, Col, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='wrap-body-inner'>
        <div>
          <div className='row'>
            <div>
              <div
                id='home'
                className='home-div'
                style={{
                  //maxHeight: "100em",
                  marginTop: "0em",
                  //width: "100vw",
                }}
              >
                {" "}
                <Row
                  className='home-row'
                  style={{
                    maxWidth: "90em",
                    margin: "auto",
                  }}
                >
                  <Col
                    sm={24}
                    md={24}
                    style={{
                      margin: "auto",
                      textAlign: "left",
                      marginTop: "13em",
                      marginBottom: "3em",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "0em",
                        color: "#3F3F3F",
                        fontWeight: 700,
                        fontSize: "30px",
                        padding: "30px",
                        paddingBottom: 20,
                        paddingTop: 0,
                        lineHeight: "1em",
                      }}
                    >
                      WEBSITE PRIVACY POLICY
                    </h2>

                    <div
                      style={{
                        margin: "auto",
                        fontWeight: 100,
                        color: "black",
                        fontWeight: 400,
                        fontSize: "17px",
                        maxWidth: "90em",
                        padding: "30px",
                        paddingTop: 0,
                        textAlign: "left",
                      }}
                    >
                      {/*Date:	*/}
                      DEFINITIONS:
                      <br />
                      <br />
                      "Company, we, us or our" <br />
                      <br />
                      1. Mega Theron Group (Pty) Ltd
                      <br />
                      <br />
                      "We, us or our" means: <br />
                      <br />
                      1. Mega Theron Group (Pty) Ltd with Reg. № 2020/570691/07
                      and Trading As:
                      <br />
                      • GWM & HAVAL Klerksdorp situated at 30 Leask Street,
                      Klerksdorp, 2571
                      <br />
                      • GWM & HAVAL Potchefstroom situated at 33 Nelson Mandela
                      Drive, Potchefstroom, 2531
                      <br />
                      • Kia & Mahindra Mahikeng situated at 2320 Aerodrome Road,
                      Mahikeng, 2735
                      <br />
                      • GWM & HAVAL Mahikeng situated at Shop 42, The Crossing,
                      C/O First & Sekame St, Mahikeng 2735
                      <br />
                      • GWM & HAVAL Lichtenburg situated at 4 Thabo Mbeki Drive,
                      Lichtenburg, 2740
                      <br />
                      2. Mega Theron Group (Pty) Ltd with Reg. № 2020/086396/07
                      and situated at 13 Joe Slovo Road, Freemanville,
                      Klerksdorp, 2571
                      <br />
                      <br />
                      3. MTG Klerksdorp (Pty) Ltd T/A Chery Klerksdorp with Reg.
                      № 2021/990251/07 and situated at C/O Bram Fischer & Leask
                      Street, Klerksdorp, 2571
                      <br />
                      <br />
                      "Our site or our website" means the site on which these
                      terms and conditions are displayed, including, but not
                      limited to the following websites (www.megatheron.co.za)
                      <br />
                      "You or your" means the person accessing or using our
                      site.
                      <br />
                      "Data Subject" means the person to whom personal
                      information relates to (belong to).
                      <br />
                      “Information Officer” means the head of a private body as
                      contemplated in section 1 of the Promotion of Access to
                      Information Act.
                      <br />
                      <br />
                      INTRODUCTION:
                      <br />
                      <br />
                      Mega Theron Group respects the privacy of our users. This
                      Privacy policy explains how we collect, use, disclose, and
                      safeguard users information when users visit our
                      (www.megatheron.co.za). It is important that you read this
                      policy carefully before submitting any personal
                      information. If you do not consent to this policy we will
                      not be able to provide its products and/or services to
                      you. <br />
                      <br />
                      Mega Theron Group whose business includes the collection
                      of Personal Information from its clients, suppliers and
                      employees (Data Subjects). The Company will comply with
                      all the relevant legislation and regulations relating to
                      the protection of Personal Information.
                      <br />
                      <br />
                      Mega Theron Group is committed to protecting and
                      respecting user’s privacy, and wish to be transparent on
                      how they process personal information and that they are
                      accountable with the Protection of Personal Information
                      Act in relation to not only processing the personal
                      information but ensuring that all clients, suppliers and
                      employees understand their rights thereto.
                      <br />
                      <br />
                      Mega Theron Group acknowledges that we must comply with
                      South African legislation, in the form of the Protection
                      of Personal Information Act, 2013 (Act no. 4 of 2013)
                      (“POPIA”) as it Processes Personal Information in South
                      Africa. In compliance with the Protection of Personal
                      Information Act 4 of 2013, we commit to process personal
                      information of Data Subjects lawfully and in a reasonable
                      manner which does not infringe the privacy of any such
                      person and according to requirements of applicable
                      legislation.
                      <br />
                      <br />
                      You are encouraged to review this Privacy Policy
                      frequently to stay informed of any updates. Mega Theron
                      Group reserves the right to change this policy at any time
                      and for any reason. <br />
                      <br />
                      WHO IS THE COMPANY?
                      <br />
                      <br />
                      Mega Theron Group will help you find your dream vehicle.
                      We offer top quality vehicles at affordable prices,
                      quality services and the best advice. We strive in
                      building an on-going relationship based on honesty and
                      integrity. We believe that you will walk in as a stranger
                      and leave as part of our family. The growth of Mega Theron
                      Group through the years is proof of personal service and
                      customer care. We at mega Theron Group falls under the
                      Retail sector as we sell passenger and commercial vehicles
                      in which it includes the entry level, sports utility
                      vehicles, luxury, light commercial, and heavy-duty
                      vehicles. It also has a number of pre-owned, passenger
                      vehicle, and commercial vehicle. We are set out to build
                      lifelong relationships, securing assets and ensuring that
                      any service provided is tailor made to each clients’
                      needs. In order for our company to provide the best
                      service to its clients, they need to obtain personal
                      information. <br />
                      <br />
                      This Privacy Policy applies to current, past or future
                      users. Furthermore this Privacy Policy also applies to any
                      third party information which was supplied by the Data
                      Subject to Mega Theron Group. <br />
                      <br />
                      HOW DOES THE COMPANY COLLECT PERSONAL INFORMATION?
                      <br />
                      <br />
                      Mega Theron Group will collect personal information
                      directly or indirectly from the Data Subjects. We will
                      process personal information for only the purpose it was
                      collected for and for which consent was given. Our Company
                      may collect further personal information through email
                      correspondence, personal meetings, and telephone calls
                      dealings with the Data Subjects. Furthermore, Mega Theron
                      Group may collect personal information about the Data
                      Subjects from third parties, if necessary and required to
                      perform a service as requested. By requesting Mega Theron
                      Group to deliver a service, and or engaging with Mega
                      Theron Group the Data Subject has consented to the
                      collection and processing of personal information and any
                      further collection necessary to fulfil the business
                      purpose.
                      <br />
                      <br />
                      WHAT PERSONAL INFORMATION IS REQUIRED FOR PROCESSING AND
                      TO DELIVER A SERVICE?
                      <br />
                      <br />
                      Mega Theron Group requires the following types of Personal
                      Information from the clients in order to be able to
                      deliver a service and to meet our legal obligations:{" "}
                      <br />
                      <br />
                      Individual details / Personal information of Data Subject:
                      <br />
                      <br />
                      1.1..1. Name and Surname, <br />
                      1.1..2. Identification document, <br />
                      1.1..3. Address (proof of residential address),
                      <br />
                      1.1..4. Gender,
                      <br />
                      1.1..5. Marital Status,
                      <br />
                      1.1..6. Nationality,
                      <br />
                      1.1..7. Employer,
                      <br />
                      1.1..8. Job title, and
                      <br />
                      1.1..9. Family details, including their relationship to
                      the client or employee.
                      <br />
                      1.1..10. Business operating address;
                      <br />
                      1.1..11. South African Income Tax registration number;
                      <br />
                      1.1..12. South African VAT registration number (if
                      applicable per individual/legal entity);
                      <br />
                      <br />
                      Contact Details:
                      <br />
                      <br />
                      1.1..13. Email address,
                      <br />
                      1.1..14. Telephone numbers, and
                      <br />
                      1.1..15. Fax number.
                      <br />
                      <br />
                      Financial details:
                      <br />
                      <br />
                      1.1..16. Banking details, and
                      <br />
                      1.1..17. Full details on assets.
                      <br />
                      1.1..18. Information regarding a claim;
                      <br />
                      1.1..19. Records of correspondence or enquiries from you
                      or anyone acting on your behalf.
                      <br />
                      1.1..20. Credit history
                      <br />
                      1.1..21. Sensitive or special personal information
                      including biometric information including but are not
                      limited to fingerprints and images. <br />
                      1.1..22. Any other information that may be requested from
                      the Data Subject, if required.
                      <br />
                      We use the information we receive and collect to provide
                      you with the information, products and/or services, to
                      refer you to an appropriate third-party service provider,
                      to communicate with you and /or to provide effective
                      advising. <br />
                      <br />
                      WHETHER PROVIDING OF PERSONAL INFORMATION IS VOLUNTARY OR
                      COMPULSORY AND THE CONSEQUENCES IF PERSONAL INFORMATION IS
                      NOT PROVIDED:
                      <br />
                      <br />
                      To fulfil Mega Theron Group purpose or required service
                      that needs to be delivered. We are required to collect the
                      personal information of Data Subjects, as set out above.
                      <br />
                      Providing personal information as required above is
                      compulsory. Mega Theron Group can decline to transact with
                      any person who refuses to comply with the requirements of
                      Legislation and / or to submit the information necessary
                      to provide a quotation and deliver a service.
                      <br />
                      <br />
                      Providing information not specifically required by
                      Legislation and/or the company as compulsory is voluntary.
                      However, Mega Theron Group reserves the right to decline
                      to transact with any person who fails to provide any
                      information which it has requested from that person and
                      which the Company deems essential, in its sole discretion.
                      <br />
                      <br />
                      HOW DOES MEGA THERON STORE AND MANAGE PERSONAL
                      INFORMATION?
                      <br />
                      The Company may store personal information electronically
                      and/or physically.
                      <br />
                      Our Company will attempt to take reasonable and
                      appropriate measures to keep such information secure.
                      However, it cannot guarantee the absolute security of
                      personal information.
                      <br />
                      By transacting with or attempting or proposing to transact
                      with Mega Theron Group in any way, you consent to the
                      storage and management of personal information described
                      in this Privacy Policy and any further storage and
                      management necessary to fulfil the business purpose.
                      <br />
                      <br />
                      PURPOSE FOR COLLECTING OF PERSONAL INFORMATION?
                      <br />
                      <br />
                      As described above, Mega Theron Group uses the personal
                      information which it collects to fulfil the business
                      purpose, that is:
                      <br />
                      • To comply with Legislation;
                      <br />
                      • To grow and safeguard its business and improve the
                      excellence of the services offered by the Company.
                      <br />
                      Where required by law, some or all of the personal
                      information collected by the Company may be disclosed to
                      the governmental authority or regulatory body.
                      <br />
                      The Company will also use the personal information
                      collected by it to conduct its business, including the
                      provision of services, marketing and billing.
                      <br />
                      By transacting with the Company, or attempting or
                      proposing to transact with the Company in any way, you
                      consent to the use of personal information described in
                      this Privacy Policy and any further use necessary to
                      fulfil the business purpose.
                      <br />
                      <br />
                      HOW LONG DOES THE COMPANY KEEP THE PERSONAL INFORMATION
                      FOR?
                      <br />
                      <br />
                      Mega Theron Group will retain personal information for the
                      period prescribed in the Legislation and for the purpose
                      it is required for.
                      <br />
                      <br />
                      Personal information must be stored for no longer than
                      necessary for the purposes for which it is processed.
                      <br />
                      <br />
                      Mega Theron Group will keep your Personal Information for
                      a period of five (5) year following the date of
                      termination of the business relationship according to
                      applicable legislation and/or requirements of the company.
                      After this period, your Personal Information will be
                      irreversibly destroyed. We also store and processes
                      personal information for so long as the Company maintains
                      a business relationship or contact with such Data Subject,
                      and for at least five years thereafter. This is a general
                      rule applied by the Company to:
                      <br />
                      <br />
                      • Ensure that it maintains personal information of all
                      Subjects who could benefit from the Company’s services;
                      and
                      <br />
                      <br />
                      • Carry out good record-keeping practices, in case the
                      details of any transaction are queried by a Data Subject,
                      governmental authority or regulatory body.
                      <br />
                      <br />
                      By transacting with the Company, or attempting or
                      proposing to transact with Mega Theron Group in any way,
                      you consent to the length of time for which the Company
                      keeps their personal information.
                      <br />
                      <br />
                      SUBJECTS’ RIGHTS TO DEAL WITH THEIR PERSONAL INFORMATION:
                      <br />
                      <br />
                      The Company views itself as the temporary custodian of
                      personal information which is entrusted to the Company and
                      curated by the Company to fulfil the business purpose.
                      Each Data Subject has the right to:
                      <br />
                      <br />
                      • Withdraw any of the consents given to the Company in
                      relation to the processing of their personal information
                      (however, the Company may continue to process the personal
                      information to the extent necessary to implement a
                      contract with the Data Subject, or to protect the
                      legitimate interests of the Data Subject, or to protect
                      the legitimate interests of the Company, or to comply with
                      any legal obligation);
                      <br />
                      <br />
                      • Ask the Company to reveal what personal information the
                      Company has relating to the Subject and who has had access
                      to that information (in which case the Company will
                      respond as soon as reasonably practicable);
                      <br />
                      <br />
                      • Ask the Company to correct or delete any of their
                      personal information, which is inaccurate, irrelevant, out
                      of date, incomplete or misleading (in which case the
                      Company will respond by altering its records, or
                      substantiating its records, as appropriate, as soon as
                      reasonably practicable), provided that the Data Subject
                      will, on request, provide all such correct and accurate
                      personal information as may be required;
                      <br />
                      <br />
                      • Ask the Company to destroy or delete any of their
                      personal information if it is no longer lawful for the
                      Company to retain it (in which case the Company will
                      respond as soon as reasonably practicable, allowing time
                      for the Company to seek legal advice if necessary);
                      <br />
                      <br />
                      • Object to the Company processing any of their personal
                      information where the processing is:
                      <br />
                      <br />
                      • Not covered by consent; or
                      <br />
                      • Not necessary for carrying out a contract between the
                      Company and for service delivery from the Company; or
                      <br />
                      • Not necessary to discharge a legal obligation of the
                      Company,
                      <br />
                      <br />
                      And the Data Subject has reasonable grounds for objection
                      (in which case the Company must cease processing the
                      personal information);
                      <br />
                      <br />
                      • Object to the Company processing any of their personal
                      information to prevent any direct marketing by the Company
                      to the Data Subject (in which case the Company must cease
                      processing the personal information for this purpose);
                      <br />
                      <br />
                      • Lodge a complaint to the Information Regulator at:
                      <br />
                      <br />
                      1.1..1. Physical Address:
                      <br />
                      JD House, 27 Stiemens Street
                      <br />
                      Braamfontein
                      <br />
                      Johannesburg, 2001
                      <br />
                      <br />
                      1.1..2. Postal Address:
                      <br />
                      PO Box 31533
                      <br />
                      Braamfontein, Johannesburg, 2017
                      <br />
                      <br />
                      1.1..3. Tel: <br />
                      010 023 5207
                      <br />
                      <br />
                      1.1..4. Email: <br />
                      complaints.IR@justice.gov.za / inforeg@justice.gov.za.
                      <br />
                      <br />
                      1.2. Within the Company’s structure, the collection and
                      processing of Data Subjects’ personal information is
                      managed by Xander Bason, who is also the Company’s
                      Information Officer. For all requests relating to the
                      processing of personal information, the Information
                      Officer may be contacted at:
                      <br />
                      <br />
                      • Email:
                      <br />
                      popi@megatheron.co.za
                      <br />
                      <br />
                      • Tel:
                      <br />
                      018 001 0218
                      <br />
                      <br />
                      SECURITY MEASURES:
                      <br />
                      <br />
                      The storage and transfer of Personal Information will
                      occur in a secure environment. <br />
                      <br />
                      Mega Theron Group will ensure that a risk assessment is
                      completed to identify all reasonably foreseeable internal
                      and external risks to Personal Information under its
                      control. <br />
                      <br />
                      Technical measures will be utilised to secure Personal
                      Information, and such measures may consist of
                      De-identification or Encryption. <br />
                      <br />
                      Mega Theron Group will ensure that the Information
                      Regulator is notified of any data breaches as soon as
                      reasonably possible and notify all Data Subjects affected
                      by such breaches.
                      <br />
                      <br />
                      RESPONSIBILITIES OF THE COMPANY:
                      <br />
                      <br />
                      Mega Theron Group is the Responsible Party, and is
                      committed to accountability, transparency and consensual
                      and responsible Processing of Personal Information.
                      <br />
                      <br />
                      Our company intends that this Policy will protect a Data
                      Subject’s Personal Information from being compromised in
                      any way, and this Policy is consistent with the privacy
                      laws applicable in South Africa.
                      <br />
                      <br />
                      The board of directors of the Company is responsible for
                      approving this Policy, and the Information Officer is
                      responsible for managing and implementing the Personal
                      Information protection processes.
                      <br />
                      <br />
                      The Company’s Information Officer will monitor Personal
                      Information protection regulation to ensure that all
                      developments are incorporated into the Company’s business
                      activities.
                      <br />
                      <br />
                      LINKS TO OTHER WEBSITES:
                      <br />
                      <br />
                      Our website or social media platforms may contain links to
                      and from websites, mobile applications or services of
                      third parties, advertisers or affiliates. Please note that
                      we are not responsible for the privacy practices of such
                      other parties and advise you to read the privacy
                      statements of each website you visit which collects
                      personal information.
                      <br />
                      <br />
                      TRANSFER OF PERSONAL INFORMATION:
                      <br />
                      <br />
                      We are based and our business operate in South Africa.
                      Your information, including personal information, may be
                      transferred to and maintained on servers located outside
                      of your country of residence, where the data privacy laws,
                      regulations and standards, may not be equivalent to the
                      laws in your country of residence.
                      <br />
                      <br />
                      We might transfer your personal information to places
                      outside of South Africa and store it there, where our
                      suppliers might process it. If that happens, your personal
                      information will only be transferred to and stored in a
                      country that has equivalent, or better, data protection
                      legislation than South Africa or with a service provider
                      which is subject to an agreement requiring it to comply
                      with data protection requirements equivalent or better
                      than those applicable in South Africa.
                      <br />
                      <br />
                      When you use our website, followed by your submission of
                      information to us, represents your consent to such
                      transfer. All steps will be reasonably necessary to ensure
                      that your personal information is treated securely and in
                      accordance with this privacy policy.
                      <br />
                      <br />
                      CONTACT DETAILS OF THE INFORMATION OFFICER:
                      <br />
                      <br />
                      Any questions relating to the Company’s Privacy Policy or
                      the treatment of an individual’s Personal Information
                      should be addressed to the Information Officer, whose
                      contact details are:
                      <br />
                      <br />
                      Information Officer: Xander Bason <br />
                      Email: popi@megatheron.co.za
                      <br />
                      Postal address: P.O. Box 307, Klerksdorp, 2570
                      <br />
                      Physical Address: 13 Joe Slovo Road Freemanville
                      Klerksdorp 2571
                    </div>
                    <Divider
                      orientation='right'
                      style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
                    >
                      <Link to='/home'>
                        <Button
                          style={{
                            float: "right",
                            height: "2.7em",
                            width: "10em",
                            fontSize: "17px",
                            fontWeight: 500,
                            borderRadius: "0.4em",
                            color: "white",
                            border: "none",
                            marginTop: "1em",
                            marginBottom: "1em",
                            backgroundColor: "rgb(207, 10, 44)",
                            textShadow: "none",
                            boxShadow: "none",
                          }}
                        >
                          Back to Home
                        </Button>
                      </Link>
                    </Divider>
                  </Col>
                </Row>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
